import { useRouter } from "vue-router";
import { useGeneralStore } from "../stores/general";

export function useExtendedRouter(fallback_route_name: string = "home") {
    const general_store = useGeneralStore();
    const router = useRouter();

    function backIfPossible() {
        if (general_store.navigation_stack.length > 1) {
            router.back();
            general_store.popFromNavigationStack();
        } else {
            router.push({ name: fallback_route_name });
        }
    }

    function canGoBack() {
        return general_store.navigation_stack.length > 1;
    }

    return {
        backIfPossible,
        canGoBack
    };
}
