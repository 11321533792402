export const PREFERENCES_KEYS = Object.freeze({
    USER_UTMS: "Analytics.Utms",

    THAS_DIALOG_VIEWED: "ThasDialogViewed",
    MOBILE_ALERT_DIALOG_TS: "MobileDialogAlertTs",

    PUSH_NOTIFICATION_DIALOG_TS: "PushNotificationDialogTs",

    AFFILIATE_PROGRAM_INVITE_ID: "Affprog.Invite.Id",
    AFFILIATE_PROGRAM_INVITE_AT: "Affprog.Invite.At",
    AFFILIATE_PROGRAM_PERMALINK_ID: "Affprog.Permalink.Id",

    QUIZ_PREFILL_CLIENT_NAME: "Quiz.Prefills.ClientName",
    QUIZ_PREFILL_CLIENT_EMAIL: "Quiz.Prefills.ClientEmail",

    AUTOUPDATER_LAST_CHECK_DATE: "Autoupdater.LastCheckDate",
    AUTOUPDATER_NATIVE_LAST_CHECK_DATE: "AutoupdaterNative.LastCheckDate",

    PRICE_CHANGE_DIALOG_V1_PRESENTED: "PriceChangeDialogV1Presented", // to musi zostać, mimo że nie jest już nigdzie wykorzystywane - ktoś może mieć to w LS i ten name nie powinien być już wykorzystywany

    CSCHECKOUT_BACK_TO_APPOINTMENT_DISPLAY_SURVEY: "Cscheckout.BackToAppointment.DisplaySurvey",

    VC2: {
        THERAPIST_EAREMINDER_STATE: "Vc2.TherapistSettings.Eareminder",
        PREFFERED_AUDIOINPUT_DEVICE: "Vc2.PreferredAudioinputDevice",
        PREFFERED_VIDEOINPUT_DEVICE: "Vc2.PreferredVideoinputDevice",
        CAMERA_EFFECT_TYPE: "Vc2.CameraEffects.EffectType",
        CAMERA_EFFECT_VIRTUAL_BACKGROUND_IMAGE: "Vc2.CameraEffects.VirtualBackgroundImage",
        LOCAL_TILE_CTX_POSITION: "Vc2.LocalTile.CtxPostion"
    },

    THERAPIST_RECRUITMENT: {
        AUTH_DATA: "TherapistRecruitmentAuthData"
    },

    ONLINE_COURSES: {
        LAST_VIEWED: "OnlineCourses.LastViewed"
    },

    REFERRAL_PARTNER: {
        ID: "ReferralPartner.Id",
        UID: "ReferralPartner.Uid"
    },

    SELFDEV_PROGRAM_CONTRACT_PARTICIPANT_ID: "SelfdevProgram.Contract.Participant.Id"
});
