<template>
    <teleport to="body">
        <transition name="fade">
            <div
                v-if="visible"
                @click="closeMenu"
                class="mobile-menu__backdrop"
            ></div>
        </transition>
        <transition name="slide">
            <div
                v-show="visible"
                class="mobile-menu"
            >
                <div class="mobile-menu__header mt-4 mb-2 mx-6">
                    <RisifyButton
                        @click="closeMenu"
                        plain
                        text
                        large
                        color="dark-green"
                    >
                        <CustomIcon
                            color="primary_dark"
                            name="close"
                        />
                    </RisifyButton>
                </div>
                <div
                    @click="
                        () => {
                            if (item.route) {
                                $router.push({
                                    name: item.route
                                });
                            } else if (item.handler) {
                                item.handler();
                            }
                            emitter.emit('DefaultLayout::hideMobileMenu');
                        }
                    "
                    class="mobile-menu__item text-body-2 py-4 px-6"
                    v-for="item in menu_items"
                >
                    <div class="mobile-menu__item-label">
                        {{ item.label }}
                    </div>
                    <div
                        class="mobile-menu__item-icon"
                        v-if="item.route !== undefined"
                    >
                        <CustomIcon
                            size="16"
                            color="primary_dark"
                            name="chevron-right"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { emitter } from "@/plugins/eventEmitter";

import CustomIcon from "@/components/generics/CustomIcon.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";

import { MenuItem } from "@/types/general";
import { UserRole } from "@/types/user";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

interface MobileMenuProps {
    visible?: boolean;
}

const auth_store = useAuthStore();
const router = useRouter();

const user_menu_items: MenuItem[] = [
    {
        label: "Moje konto",
        route: "my-account"
    },
    {
        label: "Baza wiedzy",
        route: "knowledge-blog"
    },
    {
        label: "Kalendarz",
        route: "appointments"
    },
    {
        label: "Czat z terapeutą",
        route: "chats"
    },
    {
        label: "FAQ",
        handler: () => routeWithQueryParams("help-center", { tab: "faq" })
    },
    {
        label: "Pomoc",
        handler: () => routeWithQueryParams("help-center", { tab: "helpdesk" })
    },
    {
        label: "Regulaminy i polityki",
        handler: () => window.open("https://risify.pl/regulaminy/", "_blank")
    },
    {
        label: "Wyloguj się",
        handler: () => logUserOut()
    },
    {
        label: "Wyjdź z aplikacji",
        handler: () => exitApp(),
        disabled: !Capacitor.isNativePlatform() || Capacitor.getPlatform() !== "android"
    }
];

const therapist_menu_items: MenuItem[] = [
    {
        label: "Moje konto",
        route: "my-account"
    },
    {
        label: "Wizyty",
        route: "appointments"
    },
    {
        label: "Czat",
        route: "chats"
    },
    {
        label: "FAQ",
        handler: () => routeWithQueryParams("help-center", { tab: "faq" })
    },
    {
        label: "Pomoc",
        handler: () => routeWithQueryParams("help-center", { tab: "helpdesk" })
    },
    {
        label: "Filmy instruktażowe",
        route: "onboarding-course"
    },
    {
        label: "Regulaminy i polityki",
        handler: () => window.open("https://risify.pl/regulaminy/", "_blank")
    },
    {
        label: "Wyloguj się",
        handler: () => logUserOut()
    },
    {
        label: "Wyjdź z aplikacji",
        handler: () => exitApp(),
        disabled: !Capacitor.isNativePlatform() || Capacitor.getPlatform() !== "android"
    }
];

withDefaults(defineProps<MobileMenuProps>(), {
    visible: false
});

function closeMenu() {
    emitter.emit("DefaultLayout::hideMobileMenu");
}

const menu_items = computed(() => {
    return (
        auth_store.user && auth_store.user.role === UserRole.USER
            ? user_menu_items
            : therapist_menu_items
    ).filter(it => it.disabled !== true);
});

async function logUserOut() {
    await auth_store.logUserOut({
        alert_msg: "Wylogowano pomyślnie",
        alert_type: "info",
        redirect_route_name: "welcome"
    });
}

async function exitApp() {
    if (!Capacitor.isNativePlatform()) return;
    await App.exitApp();
}

function routeWithQueryParams(route: string, query_params: Record<string, string>) {
    router.push({ name: route, query: query_params });
}
</script>
