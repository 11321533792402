import { ApiPaginatedResponse, ApiSuccessResponse } from "./api-http-requests";
import { AppointmentAppSource, AppointmentBookingProvider } from "./appointment";
import { DiscountCode } from "./discount-code";
import { PaymentIntentCurrency } from "./payment-intent";
import { Product, ProductAvailabilitySlot } from "./product";
import { User, UserPublicDataFragment } from "./user";
import { UserDiscount } from "./user-discount";

export enum CheckoutSessionProcessedItemDiscountedBy {
    PRODUCT_CREDIT = "product_credit",
    USER_DISCOUNT = "user_discount",
    DISCOUNT_CODE = "discount_code"
}

export type CheckoutSessionItem = {
    type: CheckoutSessionItemType;
    ref: string;
    pid: string;
    name: string;
    unit_gross_price: number;
    quantity: number;
    vat_rate: number;
    vat_label: string;
};
export type CheckoutSessionProcessedItem = CheckoutSessionItem & {
    base_unit_gross_price: number;
    discounted_by: CheckoutSessionProcessedItemDiscountedBy[];
};
export type CheckoutSessionBillableItem = Pick<
    CheckoutSessionItem,
    "name" | "quantity" | "vat_rate" | "vat_label"
> & {
    total_gross_price: number;
};

export type CheckoutSessionTimeriseBookings = {
    slots: CheckoutSessionAppointmentDetailsSlot[];
};
export type CheckoutSessionAppointmentDetailsSlot = Pick<
    ProductAvailabilitySlot,
    "slot_id" | "from" | "duration"
>;
export type CheckoutSessionAppointmentDetailsTimeriseData = {
    booking_provider: AppointmentBookingProvider.TIMERISE;
    timerise_bookings: Record<string, CheckoutSessionTimeriseBookings>;
};

export type CheckoutSessionRisSbBookings = {
    slots: CheckoutSessionAppointmentDetailsSlot[];
};
export type CheckoutSessionAppointmentDetailsServiceBookingData = {
    booking_provider: AppointmentBookingProvider.RISIFY_SERVICE_BOOKING;
    ris_sb: Record<string, CheckoutSessionRisSbBookings>;
};
export type CheckoutSessionAppointmentDetailsBpData =
    | CheckoutSessionAppointmentDetailsTimeriseData
    | CheckoutSessionAppointmentDetailsServiceBookingData;

export type CheckoutSessionBase = {
    _id: string;
    user: Pick<User, "email" | "first_name" | "last_name" | "phone" | "phone_cc" | "profile_image">;
    items: CheckoutSessionItem[];
    processed_items: CheckoutSessionProcessedItem[];
    billable_items: CheckoutSessionBillableItem[];
    currency: PaymentIntentCurrency;
    appointment_details?: {
        host: UserPublicDataFragment;
        participants: UserPublicDataFragment[];
        app_source: AppointmentAppSource;
    } & CheckoutSessionAppointmentDetailsBpData;

    user_discounts?: Pick<
        UserDiscount,
        "_id" | "expdate" | "quantity" | "source" | "value" | "value_type"
    >[];
    user_discounts_registers?: Record<string, string>;
    user_discounts_icv?: Record<string, number>;
    user_discounts_cv?: number;

    discount_code?: DiscountCode;
    discount_code_cv?: number;
    shipping_method: CheckoutSessionShippingMethod;
    shipping_price: number;
    base_total_gross_price: number;
    total_gross_price: number;
    expdate: number;
    expdate_ebu_enabled: boolean;
    expdate_ebu_limit: number;
    expdate_ebu_count: number;
    use_product_credits: boolean;
    held_product_credits: Record<string, number>;
    c_date: number;
    m_date: number;
};

type CheckoutSessionWithoutPaymentMethod = CheckoutSessionBase & {
    status:
        | CheckoutSessionStatus.UNPAID
        | CheckoutSessionStatus.EXPIRED
        | CheckoutSessionStatus.CANCELED;
};
type CheckoutSessionWithPaymentMethod = CheckoutSessionBase & {
    status:
        | CheckoutSessionStatus.PAID
        | CheckoutSessionStatus.SETTLED
        | CheckoutSessionStatus.PROCESSING_PAYMENT;
    payment_method: CheckoutSessionPaymentMethod;
};

export type CheckoutSession =
    | CheckoutSessionWithoutPaymentMethod
    | CheckoutSessionWithPaymentMethod;

export enum CheckoutSessionItemType {
    PRODUCT = "product",
    APPOINTMENT = "appointment"
}

export enum CheckoutSessionStatus {
    UNPAID = "unpaid",
    PROCESSING_PAYMENT = "processing_payment",
    PAID = "paid",
    SETTLED = "settled",
    EXPIRED = "expired",
    CANCELED = "canceled"
}

export enum CheckoutSessionShippingMethod {
    ELECTRONIC = "electronic"
}

export enum PaymentIntentPaymentMethod {
    STRIPE_CARD_ON_SESSION = "stripe_card_on_session",
    STRIPE_P24 = "stripe_p24",
    P24 = "p24",
    P24_BLIK = "p24_blik"
}

export enum CheckoutSessionPaymentMethod {
    STRIPE_CARD_ON_SESSION = PaymentIntentPaymentMethod.STRIPE_CARD_ON_SESSION,
    P24 = "p24",
    P24_BLIK = "p24_blik"
}

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiCheckoutSessionsResponse = ApiPaginatedResponse & {
    checkout_sessions: CheckoutSession[];
};
export type ApiCheckoutSessionResponse = ApiSuccessResponse & {
    checkout_session: CheckoutSession;
    products: Product[];
};
export type ApiCheckoutSessionPaymentMethodsResponse = ApiSuccessResponse & {
    payment_methods: PaymentIntentPaymentMethod[];
};
export type ApiCheckoutSessionPayResponse = ApiSuccessResponse &
    (
        | {
              payment_channel: "none" | PaymentIntentPaymentMethod.P24_BLIK;
          }
        | {
              payment_channel: PaymentIntentPaymentMethod.STRIPE_CARD_ON_SESSION;
              payment_intent_client_secret: string;
              stripe_pm_id?: string;
          }
        | {
              payment_channel: PaymentIntentPaymentMethod.P24;
              redirect_url: string;
          }
    );
