<template>
    <teleport to="body">
        <transition name="fade">
            <div
                v-if="is_open"
                :style="{ zIndex: backdrop_z_index }"
                @click="onPressCancel"
                :class="{ dialog__backdrop: !fullscreen }"
            ></div>
        </transition>
        <transition name="dialog-slide">
            <div
                v-if="is_open"
                :style="{ zIndex: dialog_z_index, maxWidth: `${parsed_width}px` }"
                class="dialog mx-2 pa-md-0"
                :class="{
                    'dialog--fullscreen': fullscreen
                }"
                v-bind="$attrs"
                ref="dialog_ref"
            >
                <div
                    v-if="hasSlot('header')"
                    class="dialog__header"
                >
                    <slot name="header" />
                </div>
                <div
                    class="dialog__main"
                    ref="dialog_main_ref"
                >
                    <slot />
                </div>
                <div
                    v-if="hasSlot('footer')"
                    class="dialog__footer"
                >
                    <slot name="footer" />
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, useSlots, nextTick } from "vue";
import { watchDebounced } from "@vueuse/core";
import { getMaxZIndex } from "@/helpers/layout";
import { useDialogs } from "@/helpers/dialogs";

export type DialogProps = {
    modelValue: boolean;
    fullscreen?: boolean;
    persistent?: boolean;
    width?: number | string;
};

export type DialogExpose = {
    getMainElement: () => HTMLElement | undefined;
};

/*###########
### SETUP ###
###########*/
defineOptions({
    inheritAttrs: false,
    name: "Dialog"
});

const props = withDefaults(defineProps<DialogProps>(), {
    fullscreen: false,
    modelValue: false,
    persistent: false,
    width: 600
});

const emit = defineEmits<{
    (e: "update:modelValue", v: boolean): void;
    (e: "cancel"): void;
    (e: "keydown", v: KeyboardEvent): void;
}>();

defineExpose({
    getMainElement: () => dialog_main_ref.value
});

/*###############
### VARIABLES ###
###############*/
const is_open = ref<boolean>(false);
const dialog_ref = ref<HTMLElement | null>(null);
const dialog_main_ref = ref<HTMLElement | null>(null);
const backdrop_z_index = ref(1);
const dialog_z_index = ref(2);
let od_regid: string = "";

/*###########
### HOOKS ###
###########*/
const slots = useSlots();
const { registerOpenedDialog, unregisterOpenedDialog } = useDialogs();

/*##############
### COMPUTED ###
##############*/
const parsed_width = computed(() => {
    const PV = parseInt(props.width.toString());
    if (isNaN(PV)) return 600;
    return PV;
});

/*##############
### WATCHERS ###
##############*/
watchDebounced(
    () => props.modelValue,
    (nv, ov) => {
        if (nv === ov || (nv === false && !is_open.value) || (nv === true && is_open.value)) {
            return;
        }

        if (nv === true) {
            const MZI = getMaxZIndex();
            backdrop_z_index.value = MZI + 1;
            dialog_z_index.value = MZI + 2;

            od_regid = registerOpenedDialog();
            nextTick(() => {
                is_open.value = true;
            });
        } else {
            unregisterOpenedDialog(od_regid);
            nextTick(() => {
                is_open.value = false;
            });
        }
    },
    {
        immediate: true,
        debounce: 10,
        maxWait: 25
    }
);

/*#############
### METHODS ###
#############*/
function hasSlot(name: keyof typeof slots) {
    return !!slots[name];
}

function onPressCancel() {
    if (!props.persistent) {
        emit("update:modelValue", false);
        emit("cancel");
    }
}

function onKeyDown(e: KeyboardEvent) {
    emit("keydown", e);
    if (e.key === "Escape" && !props.persistent) {
        onPressCancel();
    }
}

/*#####################
### LIFESTYLE HOOKS ###
#####################*/
onMounted(() => {
    document.body.addEventListener("keydown", onKeyDown);
});

onUnmounted(() => {
    document.body.removeEventListener("keydown", onKeyDown);
});
</script>
