import mitt from "mitt";
import {
    EmitterDefaultLayoutScrollLayoutArgs,
    QuizBoardAnswerValidEventParams
} from "../types/emitter";
import { Appointment } from "@/types/appointment";
import {
    VideoCallTwilio2AttachAudioEventParams,
    VideoCallTwilio2AttachVideoEventParams,
    VideoCallTwilio2DetachAudioEventParams,
    VideoCallTwilio2DetachVideoEventParams
} from "@/views/video-call/video-call-twilio-2.vue";
import { PushNotificationSchema } from "@capacitor/push-notifications";

type Events = {
    "Dialog::close-dialog": boolean;

    "RisifySelect::opened": string;

    "CreditCard::selected": string;

    "DefaultLayout::showMobileMenu": void;
    "DefaultLayout::hideMobileMenu": void;
    "DefaultLayout::scrollLayout": EmitterDefaultLayoutScrollLayoutArgs;
    "DefaultLayout::showBottomNavigation": void;
    "DefaultLayout::hideBottomNavigation": void;
    "DefaultLayout::showOfflineDialog": void;
    "DefaultLayout::showPushNotificationDialog": PushNotificationSchema;

    "AppointmentDetailsView::display-appointment": Appointment;
    "AppointmentDetailsView::close": void;
    "AppointmentDetailsView::updated": Appointment;
    "AppointmentDetailsView::ask-for-ths-feedback": boolean;

    "AppointmentCalendarView::refresh": void;

    "QuizBoard::answer-valid": QuizBoardAnswerValidEventParams;
    "QuizBoard::answer-invalid": string;
    "QuizBoard::override-next-button-text": string;
    "QuizBoard::override-next-button-disabled-state": boolean;
    "QuizBoard::override-next-button-visibility-state": boolean;
    "QuizBoard::override-scrolldown-button-visibility-state": boolean;

    "ContentBlock::asl-entered": string;

    "Browser::activestatechange": boolean;

    "VideoCallTwilio2::attach-video": VideoCallTwilio2AttachVideoEventParams;
    "VideoCallTwilio2::detach-video": VideoCallTwilio2DetachVideoEventParams;
    "VideoCallTwilio2::attach-audio": VideoCallTwilio2AttachAudioEventParams;
    "VideoCallTwilio2::detach-audio": VideoCallTwilio2DetachAudioEventParams;
};

export const emitter = mitt<Events>();
