import { Store, defineStore } from "pinia";
import { ref } from "vue";
import { ILazyLoadInstance } from "vanilla-lazyload";
import { RouteRecordName } from "vue-router";
import { User, UserRole } from "@/types/user";
import { flushEnqueuedRequests, holdEnqueuedRequests, httpJsonRequest } from "@/plugins/fetchApi";
import { ApiRandomQuizResponse } from "@/types/quiz";

export const useGeneralStore = defineStore("general", () => {
    /*###################
    ### ENV VARIABLES ###
    ###################*/
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    const SELF_BASE_URL = import.meta.env.VITE_SELF_BASE_URL;
    const API_BASE_PATH = `${API_BASE_URL}${import.meta.env.VITE_API_BASE_PATH}`;

    /*#########
    ### APP ###
    #########*/
    const app_booted = ref<boolean>(false);
    const app_boot_promise: Promise<void> = new Promise<void>(resolve => {
        function c() {
            if (app_booted.value) {
                return resolve();
            }

            setTimeout(c, 250);
        }
        c();
    });
    function setAppBooted(state: boolean) {
        app_booted.value = state;
    }

    const THERAPIST_EXCLUDED_PRODUCTS: string[] = [
        "660150096c5bc90ca679d213",
        "663e1e5e5a94bf47f704712b"
    ]; // ID produktów, które nie mają być widoczne na liście produktów do umówienia wizyty dla terapeuty pomimo, że ma je przypisane

    /*#########################
    ### MAIN LAYOUT HELPERS ###
    #########################*/
    const main_layout_last_scroll_position = ref<number>(0);
    function getMainLayoutLastScrollPosition() {
        return main_layout_last_scroll_position.value;
    }
    function setMainLayoutLastScrollPosition(v: number) {
        main_layout_last_scroll_position.value = v;
    }

    const main_layout_last_scroll_height = ref<number>(0);
    function getMainLayoutLastScrollHeight() {
        return main_layout_last_scroll_height.value;
    }
    function setMainLayoutLastScrollHeight(v: number) {
        main_layout_last_scroll_height.value = v;
    }

    const main_layout_last_offset_height = ref<number>(0);
    function getMainLayoutLastOffsetHeight() {
        return main_layout_last_offset_height.value;
    }
    function setMainLayoutLastOffsetHeight(v: number) {
        main_layout_last_offset_height.value = v;
    }

    const main_layout_client_width = ref<number>(0);
    function getMainLayoutClientWidth() {
        return main_layout_client_width.value;
    }
    function setMainLayoutClientWidth(v: number) {
        main_layout_client_width.value = v;
    }

    const main_layout_scrollbar_width = ref<number>(0);
    function getMainLayoutScrollbarWidth() {
        return main_layout_scrollbar_width.value;
    }
    function setMainLayoutScrollbarWidth(v: number) {
        main_layout_scrollbar_width.value = v;
    }

    /*####################
    ### IMG LAZYLOADER ###
    ####################*/
    const image_lazyloader_instance = ref<null | ILazyLoadInstance>(null);
    function setImageLazyloaderInstance(d: null | ILazyLoadInstance) {
        image_lazyloader_instance.value = d;
    }

    /*##########
    ## OTHERS ##
    ##########*/
    const app_is_focused = ref<boolean>(true);
    function setAppIsFocused(state: boolean) {
        app_is_focused.value = state;
    }

    const navigation_stack = ref<RouteRecordName[]>([]);
    function pushToNavigationStack(r: RouteRecordName) {
        navigation_stack.value.push(r);
    }
    function popFromNavigationStack() {
        navigation_stack.value.pop();
    }
    const navigation_last_route_name = ref<RouteRecordName | null>(null);
    function setNavigationLastRouteName(route_name: RouteRecordName) {
        navigation_last_route_name.value = route_name;
    }

    const is_online = ref<boolean>(true);
    function setIsOnline(state: boolean) {
        is_online.value = state;

        if (state === true) {
            flushEnqueuedRequests();
        } else {
            holdEnqueuedRequests();
        }
    }

    /*########################
    ### VIDEOCALL ACTIVITY ###
    ########################*/
    const active_video_call = ref<boolean>(false);
    function isVideoCallActive() {
        return active_video_call.value;
    }
    function setVideoCallActive(state: boolean) {
        active_video_call.value = state;
    }
    function getActiveVideoCall() {
        return active_video_call.value;
    }

    /*#############
    ### GLOBALS ###
    #############*/
    async function redirectUserToAppropriateScheduleAppointmentFlow(this: Store, user: User) {
        if (user.role !== UserRole.USER) return;

        // 1. Jeżeli osoba ma przypisanego terapeutę lub produkt, to leci do umówienia wizyty
        if (user.user_current_therapist || user.user_current_ths_product) {
            this.router.push({
                name: "wiz-fn1-service-and-date"
            });
            return;
        }
        // 2. Jeżeli nie mamy terapeuty i produktu, ale mamy THID to ONBOARDING
        if (user.user_thid) {
            this.router.push({
                name: "wiz-fn2-onboarding"
            });
            return;
        }
        // 3. Jeżeli nie ma THID, to quiz
        if (!user.user_thid) {
            try {
                const r = await httpJsonRequest<ApiRandomQuizResponse>(`/quizzes/grabg/diagnosis`);
                if (r.quiz) {
                    this.router.push({
                        name: "quiz",
                        params: {
                            url_name: r.quiz.url_name
                        }
                    });
                    return;
                }
            } catch (err) {
                console.error(err);
            }
        }

        // 4. Fallback
        this.router.push({
            name: "wiz-fn1-service-and-date"
        });
        return;
    }

    return {
        API_BASE_URL,
        API_BASE_PATH,
        SELF_BASE_URL,

        THERAPIST_EXCLUDED_PRODUCTS,

        app_booted,
        app_boot_promise,
        setAppBooted,

        main_layout_last_scroll_position,
        main_layout_last_scroll_height,
        main_layout_last_offset_height,
        main_layout_client_width,
        main_layout_scrollbar_width,
        getMainLayoutLastScrollPosition,
        setMainLayoutLastScrollPosition,
        getMainLayoutLastScrollHeight,
        setMainLayoutLastScrollHeight,
        getMainLayoutLastOffsetHeight,
        setMainLayoutLastOffsetHeight,
        getMainLayoutClientWidth,
        setMainLayoutClientWidth,
        getMainLayoutScrollbarWidth,
        setMainLayoutScrollbarWidth,

        is_online,
        setIsOnline,

        app_is_focused,
        setAppIsFocused,

        navigation_stack,
        pushToNavigationStack,
        popFromNavigationStack,
        navigation_last_route_name,
        setNavigationLastRouteName,

        isVideoCallActive,
        setVideoCallActive,
        getActiveVideoCall,

        image_lazyloader_instance,
        setImageLazyloaderInstance,

        redirectUserToAppropriateScheduleAppointmentFlow
    };
});
