export enum UserDeviceType {
    DESKTOP = "desktop",
    SMARTPHONE = "smartphone",
    TABLET = "tablet",
    FEATURE_PHONE = "feature phone",
    CONSOLE = "console",
    TV = "tv",
    CAR_BROWSER = "car browser",
    SMART_DISPLAY = "smart display",
    CAMERA = "camera",
    PORTABLE_MEDIA_PLAYER = "portable media player",
    PHABLET = "phablet",
    SMART_SPEAKER = "smart speaker",
    WEARABLE = "wearable",
    PERIPHERAL = "peripheral",
    UNKNOWN = "unknown"
}

export enum UserDeviceClientType {
    MOBILE_APP = "mobile app",
    BROWSER = "browser",
    LIBRARY = "library",
    MEDIA_PLAYER = "mediaplayer",
    FEED_READER = "feed reader",
    PIM = "pim",
    UNKNOWN = "unknown"
}

export enum PushSubscriptionProvider {
    GCM = "gcm",
    APN = "apn"
}

export type UserDevice = {
    _id: string;
    user: string;

    device_type: UserDeviceType;
    device_brand: string;
    device_model: string;

    client_type: UserDeviceClientType;
    client_name: string;
    client_version: string;
    client_engine_name: string;
    client_engine_version: string;
    client_family: string;

    os_name: string;
    os_short_name: string;
    os_version: string;
    os_platform: string;
    os_family: string;

    push_subscription?: string;

    c_date: number;
    m_date: number;
};
