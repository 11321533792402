<template>
    <div
        class="chmaec"
        ref="chmaec"
        v-if="
            chatMessage.deleted === false &&
            chatMessage.message_type === ChatMessageMessageType.EVENT
        "
    >
        <!-- TYTUŁ ZDARZENIA Z PODZIAŁEM SYSTEM / USER -->
        <div class="chmaec__title text-link-2 text-weight-semibold mb-2">
            <template v-if="chatMessage.author_type === ChatMessageAuthorType.USER">
                <template
                    v-if="chatMessage.event.name === ChatMessageEventName.APPOINTMENT_EPF_SCHEDULED"
                >
                    {{ author_name }}
                    umówił(‑a) i opłacił(‑a) nową wizytę:
                </template>
                <template
                    v-else-if="
                        chatMessage.event.name === ChatMessageEventName.APPOINTMENT_MPF_SCHEDULED
                    "
                >
                    {{ author_name }}
                    umówił(‑a) nową wizytę:
                </template>
                <template
                    v-else-if="chatMessage.event.name === ChatMessageEventName.APPOINTMENT_MPF_PAID"
                >
                    {{ author_name }}
                    opłacił(‑a) wizytę:
                </template>
                <template
                    v-else-if="
                        chatMessage.event.name === ChatMessageEventName.APPOINTMENT_RESCHEDULED
                    "
                >
                    {{ author_name }}
                    przełożył(‑a) wizytę:
                </template>
                <template
                    v-else-if="chatMessage.event.name === ChatMessageEventName.APPOINTMENT_CANCELED"
                >
                    {{ author_name }}
                    anulował(‑a) wizytę:
                </template>
                <template
                    v-else-if="chatMessage.event.name === ChatMessageEventName.APPOINTMENT_STARTED"
                >
                    {{ author_name }} rozpoczął(‑ęła) wizytę:
                </template>
                <template
                    v-else-if="
                        chatMessage.event.name === ChatMessageEventName.APPOINTMENT_COMPLETED
                    "
                >
                    Wizyta zakończona:
                </template>
            </template>
            <template v-else-if="chatMessage.author_type === ChatMessageAuthorType.SYSTEM">
                {{ ChatMessageEventNameLabel[chatMessage.event.name] }}:
            </template>
        </div>

        <!-- SZCZEGÓŁY SPOTKANIA -->
        <Transition
            name="fade"
            mode="out-in"
        >
            <div
                class="chmaec__loader skeleton-loader-box"
                v-if="!data_loaded"
            ></div>
            <div
                class="chmaec__appointment"
                v-else
            >
                <div
                    v-if="appointment"
                    :class="{ 'chmaec-apptile': true, 'pa-3 pa-md-4': !noPadding }"
                    @click="
                        () => {
                            if (appointment) {
                                emitter.emit(
                                    'AppointmentDetailsView::display-appointment',
                                    appointment
                                );
                            }
                        }
                    "
                >
                    <!-- NAZWA PRODUKTU -->
                    <div class="text-link-3 text-weight-semibold mb-1">
                        {{ appointment.product.name }}
                    </div>

                    <!-- DATY I GODZINY -->
                    <!-- 1. POPRZEDNIA DATA I GODZINA SPOTKANIA (przed przełożeniem) -->
                    <div
                        v-if="
                            chatMessage.event.name ===
                                ChatMessageEventName.APPOINTMENT_RESCHEDULED &&
                            appointment.prev_start_times.length > 0
                        "
                        class="chmaec-apptile-stime"
                    >
                        <div
                            class="chmaec-apptile-stime__element chmaec-apptile-stime__element--crossedout"
                        >
                            <CustomIcon
                                name="calendar"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div class="pr-2">
                                {{
                                    moment(
                                        appointment.prev_start_times[chatMessage.event.pstix]
                                            .start_time
                                    )
                                        .tz(auth_store.getUserTimezone())
                                        .format("DD.MM.YYYY")
                                }}
                            </div>
                        </div>
                        <div
                            class="chmaec-apptile-stime__element chmaec-apptile-stime__element--crossedout"
                        >
                            <CustomIcon
                                name="clock"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div class="pr-2">
                                {{
                                    moment(
                                        appointment.prev_start_times[chatMessage.event.pstix]
                                            .start_time
                                    )
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm") +
                                    " - " +
                                    moment(
                                        appointment.prev_start_times[chatMessage.event.pstix]
                                            .start_time
                                    )
                                        .tz(auth_store.getUserTimezone())
                                        .add(
                                            moment.duration(appointment.duration).asMilliseconds(),
                                            "milliseconds"
                                        )
                                        .format("HH:mm")
                                }}
                            </div>
                        </div>
                    </div>
                    <!-- 2. AKTUALNA DATA (przekreślona jeżeli spotkanie anulowane) -->
                    <div class="chmaec-apptile-stime">
                        <div
                            class="chmaec-apptile-stime__element"
                            :class="{
                                'chmaec-apptile-stime__element--crossedout':
                                    appointment.status === AppointmentStatus.CANCELED
                            }"
                        >
                            <CustomIcon
                                name="calendar"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div
                                class="pr-2"
                                v-if="
                                    chatMessage.event.name ===
                                        ChatMessageEventName.APPOINTMENT_RESCHEDULED &&
                                    appointment.prev_start_times.length > 0 &&
                                    appointment.prev_start_times[chatMessage.event.pstix + 1]
                                "
                            >
                                {{
                                    moment(
                                        appointment.prev_start_times[chatMessage.event.pstix + 1]
                                            .start_time
                                    )
                                        .tz(auth_store.getUserTimezone())
                                        .format("DD.MM.YYYY")
                                }}
                            </div>
                            <div
                                class="pr-2"
                                v-else
                            >
                                {{
                                    moment(appointment.start_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("DD.MM.YYYY")
                                }}
                            </div>
                        </div>
                        <div
                            class="chmaec-apptile-stime__element"
                            :class="{
                                'chmaec-apptile-stime__element--crossedout':
                                    appointment.status === AppointmentStatus.CANCELED
                            }"
                        >
                            <CustomIcon
                                name="clock"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div
                                class="pr-2"
                                v-if="
                                    chatMessage.event.name ===
                                        ChatMessageEventName.APPOINTMENT_RESCHEDULED &&
                                    appointment.prev_start_times.length > 0 &&
                                    appointment.prev_start_times[chatMessage.event.pstix + 1]
                                "
                            >
                                {{
                                    moment(
                                        appointment.prev_start_times[chatMessage.event.pstix + 1]
                                            .start_time
                                    )
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm") +
                                    " - " +
                                    moment(
                                        appointment.prev_start_times[chatMessage.event.pstix + 1]
                                            .start_time
                                    )
                                        .tz(auth_store.getUserTimezone())
                                        .add(
                                            moment.duration(appointment.duration).asMilliseconds(),
                                            "milliseconds"
                                        )
                                        .format("HH:mm")
                                }}
                            </div>
                            <div
                                class="pr-2"
                                v-else
                            >
                                {{
                                    moment(appointment.start_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm") +
                                    " - " +
                                    moment(appointment.end_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm")
                                }}
                            </div>
                        </div>
                    </div>

                    <!-- OPCJONALNY FOOTER Z CTA -->
                    <div
                        class="chmaec-apptile__footer"
                        v-if="(display_join_vc_cta || display_mpf_pay_cta) && !hideFooter"
                    >
                        <!-- Przycisk do opłacenia (tylko, gdy appointment status jest unpaid) -->
                        <div
                            v-if="display_mpf_pay_cta"
                            class="chmaec-apptile__unpaidcta"
                        >
                            <RisifyButton
                                small
                                text
                                icon
                                color="yellow"
                                @click.stop="redirectToPayment"
                            >
                                <CustomIcon
                                    name="money"
                                    class="mr-1"
                                />
                                Opłać
                            </RisifyButton>
                        </div>
                        <!-- Przycisk dołączenia do spotkania (tylko, gdy event to appointment_started i appointment status to in progress) -->
                        <div
                            v-else-if="display_join_vc_cta"
                            class="chmaec-apptile__joinvc"
                        >
                            <RisifyButton
                                small
                                text
                                icon
                                color="green"
                                @click.stop="onJoinAppointmentClick"
                            >
                                <CustomIcon
                                    name="record"
                                    class="mr-1"
                                />
                                Dołącz do spotkania
                            </RisifyButton>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="chmaec__nad mt-n1 text-link-3"
                >
                    Nie możesz wyświetlić tego elementu
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useIntersectionObserver } from "@vueuse/core";

import { emitter } from "@/plugins/eventEmitter";

import { useAuthStore } from "@/stores/auth";
import { useFetchedElementsStore } from "@/stores/fetched-elements";

import moment from "@/helpers/moment";

import { AppointmentStatus } from "@/types/appointment";
import {
    ChatMessage,
    ChatMessageAuthorType,
    ChatMessageEventName,
    ChatMessageEventNameLabel,
    ChatMessageMessageType
} from "@/types/chat-message";

import CustomIcon from "@/components/generics/CustomIcon.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";

/*###########
### SETUP ###
###########*/
const props = withDefaults(
    defineProps<{
        chatMessage: ChatMessage;
        noPadding?: boolean;
        hideFooter?: boolean;
        externalRedirects?: boolean;
    }>(),
    {
        noPadding: false,
        hideFooter: false,
        externalRedirects: false
    }
);
const auth_store = useAuthStore();
const fetched_elements = useFetchedElementsStore();
const router = useRouter();

/*#########################
### IO-BASED DATA FETCH ###
#########################*/
const data_loaded = ref<boolean>(false);
const chmaec = ref<HTMLElement>();
const chmaec_id = ref<string>("");
function onIntersect(entries: IntersectionObserverEntry[]) {
    if (entries.length > 0 && entries[0].isIntersecting) {
        fetchData();
    }
}
const { stop: stopIoWatch } = useIntersectionObserver(chmaec, onIntersect, { threshold: 0.1 });

async function fetchData() {
    if (
        props.chatMessage.deleted === false &&
        props.chatMessage.message_type === ChatMessageMessageType.EVENT
    ) {
        if (
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_EPF_SCHEDULED ||
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_MPF_SCHEDULED ||
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_MPF_PAID ||
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_RESCHEDULED ||
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_CANCELED ||
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_STARTED ||
            props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_COMPLETED
        ) {
            try {
                const R = await fetched_elements.getAppointment(
                    props.chatMessage.event.subject_appointment
                );
                chmaec_id.value = R._id;
            } catch (err) {
                console.error(err);
            }

            data_loaded.value = true;
        }
    }

    stopIoWatch();
}

/*##########
### DATA ###
##########*/
const appointment = computed(() => {
    return fetched_elements.retrieveAppointment(chmaec_id.value);
});

/*#####################
### DISPLAY HELPERS ###
#####################*/
const author_name = computed(() => {
    if (props.chatMessage.author_type === ChatMessageAuthorType.USER) {
        return props.chatMessage.user.first_name + " " + props.chatMessage.user.last_name;
    }
    return "";
});
const display_mpf_pay_cta = computed(() => {
    return (
        props.chatMessage.deleted === false &&
        props.chatMessage.message_type === ChatMessageMessageType.EVENT &&
        props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_MPF_SCHEDULED &&
        auth_store.user &&
        appointment.value &&
        appointment.value.owner === auth_store.user._id &&
        appointment.value.status === AppointmentStatus.UNPAID
    );
});
const display_join_vc_cta = computed(() => {
    return (
        props.chatMessage.deleted === false &&
        props.chatMessage.message_type === ChatMessageMessageType.EVENT &&
        props.chatMessage.event.name === ChatMessageEventName.APPOINTMENT_STARTED &&
        appointment.value &&
        appointment.value.status === AppointmentStatus.IN_PROGRESS
    );
});

/*######################
### MPF PAYMENT INIT ###
######################*/
function redirectToPayment() {
    if (appointment.value === undefined) return;

    if (props.externalRedirects) {
        const RR = router.resolve({
            name: "wiz-fn1-pay-appointment",
            params: { id: appointment.value._id }
        });
        window.open(RR.href, "_blank");
    } else {
        router.push({
            name: "wiz-fn1-pay-appointment",
            params: { id: appointment.value._id }
        });
    }
}

/*#######################
### JOIN APPOINTEMENT ###
#######################*/
function onJoinAppointmentClick() {
    if (
        props.chatMessage.deleted ||
        props.chatMessage.message_type !== ChatMessageMessageType.EVENT ||
        props.chatMessage.event.name !== ChatMessageEventName.APPOINTMENT_STARTED
    ) {
        return;
    }
    router.push({
        name: "vc",
        params: {
            id: props.chatMessage.event.subject_appointment
        }
    });
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onUnmounted(async () => {
    stopIoWatch();
});
</script>
