<template>
    <div
        id="app"
        class="app"
    >
        <AutoUpdater />
        <AutoUpdaterNative />
        <DefaultLayout>
            <router-view
                v-if="generalStore.app_booted"
                v-slot="{ Component }"
                key="app-booted"
            >
                <transition
                    name="fade"
                    mode="out-in"
                    appear
                >
                    <component
                        :is="Component"
                        :key="route_key"
                    />
                </transition>
            </router-view>
            <div
                v-else
                class="app"
                key="app-not-booted"
            >
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        class="app-loading"
                        v-if="generalStore.is_online"
                        key="app-loading"
                    >
                        <div class="app-loading__spinner">
                            <Spinner
                                color="primary"
                                size="24"
                                width="4"
                            />
                        </div>
                        <div
                            class="app-loading__text"
                            v-t="'App.loading'"
                        ></div>
                    </div>
                </Transition>
            </div>
        </DefaultLayout>

        <Transition name="slide-fade-down-16p">
            <div
                class="app-exit-app-info"
                v-if="back_button_app_exit_info_visible"
            >
                <div class="app-exit-app-info__chip">
                    Naciśnij "Wstecz" ponownie, aby wyjść z aplikacji
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import { useNotivue } from "notivue";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { Preferences } from "@capacitor/preferences";
import { Style } from "@capacitor/status-bar";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { SplashScreen } from "@capacitor/splash-screen";
import { PushNotifications } from "@capacitor/push-notifications";
import { SafeArea, SafeAreaInsets, StatusBarInfo } from "capacitor-plugin-safe-area";
import { Badge } from "@capawesome/capacitor-badge";
import { App } from "@capacitor/app";
import { Keyboard } from "@capacitor/keyboard";

import { useGeneralStore } from "./stores/general";
import { useBreakpoints } from "./helpers/breakpoints";
import { useStatusBar } from "./helpers/statusbar";
import { Stopwatch, waitForMs } from "./helpers/waiters";
import { useAuthStore } from "./stores/auth";
import { clearUnusedCaches } from "./plugins/browserCache";

import DefaultLayout from "./layouts/DefaultLayout.vue";
import Spinner from "@/components/loaders/Spinner.vue";
import AutoUpdater from "@/components/generics/AutoUpdater.vue";
import AutoUpdaterNative from "@/components/generics/AutoUpdaterNative.vue";

import { AuthData } from "./types/auth";
import { usei18n } from "./plugins/i18n";
import {
    handlePushNotificationActionPerformed,
    handlePushNotificationReceived
} from "./helpers/push-notifications";
import { useExtendedRouter } from "./helpers/navigation";

/*###########
### SETUP ###
########## */
const route = useRoute();
const generalStore = useGeneralStore();
const authStore = useAuthStore();
const { is_desktop } = useBreakpoints();
const { locale, t } = usei18n();
const { setStatusBarStyle } = useStatusBar();
const extendedRouter = useExtendedRouter();

useHead({
    title: t("global.meta.generic_title"),
    titleTemplate: t("global.meta.title_template"),
    htmlAttrs: {
        lang: locale
    }
});

/*#############
### GENERAL ###
############ */
const CAPACITOR_EVENT_LISTENERS_HANDLES: PluginListenerHandle[] = [];

const route_key = computed(() => {
    if (route.name === "quiz" || route.name === "quiz-board") return "quiz";
    if (
        route.name === "my-account-general-data" ||
        route.name === "my-account-login-data-and-security" ||
        route.name === "my-account-notifications" ||
        route.name === "my-account-payments" ||
        route.name === "my-account-recommend-friends" ||
        route.name === "my-account-recommend-friends-sent" ||
        route.name === "my-account-linked-users" ||
        route.name === "my-account-experience" ||
        route.name === "my-account-education" ||
        route.name === "my-account-products" ||
        route.name === "my-account-availability" ||
        route.name === "my-account-settings"
    ) {
        return "my-account";
    }
    return route.fullPath;
});

/*#############
### NOTIVUE ###
############ */
const notivueConfig = useNotivue();

watch(
    is_desktop,
    () => {
        if (is_desktop.value) {
            notivueConfig.position.value = "bottom-left";
        } else {
            notivueConfig.position.value = "top-center";
        }
    },
    { immediate: true }
);

/*####################
### NETWORK STATUS ###
################### */
watch(
    () => generalStore.is_online,
    (nv, ov) => {
        if (nv && !generalStore.app_booted) {
            appBoot();
        }

        if (nv && !ov) {
            if (authStore.user_data_invalidated) {
                authStore.fetchUserData();
            }
        }
    }
);

function onOnline() {
    generalStore.setIsOnline(true);
}

function onOffline() {
    generalStore.setIsOnline(false);
}

/*########################
### SCREEN ORIENTATION ###
####################### */
const lockScreenOrientation = async () => {
    try {
        await ScreenOrientation.lock({ orientation: "portrait" });
    } catch (error) {
        console.error(error);
    }
};

/*#####################
### NATIVE KEYBOARD ###
#####################*/
const native_keyboard_visible = ref<boolean>(false);

function onNativeKeyboardWillShow() {
    native_keyboard_visible.value = true;
}
function onNativeKeyboardWillHide() {
    native_keyboard_visible.value = false;
}

/*######################
### SAFE AREA INSETS ###
######################*/
const safe_area_insets_raw = ref<SafeAreaInsets>({
    insets: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
});
const status_bar_height = ref<StatusBarInfo>({
    statusBarHeight: 0
});

const safe_area_insets = computed(() => {
    if (native_keyboard_visible.value === false) return safe_area_insets_raw.value;
    return {
        insets: {
            top: safe_area_insets_raw.value.insets.top,
            left: safe_area_insets_raw.value.insets.left,
            right: safe_area_insets_raw.value.insets.right,
            bottom: 0
        }
    } as SafeAreaInsets;
});

function handleSafeAreaChange(data: SafeAreaInsets) {
    safe_area_insets_raw.value = data;
}
watch(safe_area_insets, nv => {
    document.body.style.setProperty("--ris-safe-area-inset-top", nv.insets.top + "px");
    document.body.style.setProperty("--ris-safe-area-inset-right", nv.insets.right + "px");
    document.body.style.setProperty("--ris-safe-area-inset-bottom", nv.insets.bottom + "px");
    document.body.style.setProperty("--ris-safe-area-inset-left", nv.insets.left + "px");
});

/*#########################
### ANDROID BACK BUTTON ###
#########################*/
const last_back_button_click_ts = ref<number>(0);
const back_button_app_exit_info_visible = ref<boolean>(false);
let back_button_app_exit_info_debouncer: NodeJS.Timeout | null = null;

function showAppExitInfo() {
    if (back_button_app_exit_info_debouncer !== null) {
        clearTimeout(back_button_app_exit_info_debouncer);
    }

    back_button_app_exit_info_visible.value = true;
    back_button_app_exit_info_debouncer = setTimeout(() => {
        back_button_app_exit_info_visible.value = false;
        back_button_app_exit_info_debouncer = null;
    }, 1500);
}
function onAndroidBackButtonClick() {
    if (
        !extendedRouter.canGoBack() ||
        ["home", "offline", "welcome"].indexOf(route.name ? route.name.toString() : "") !== -1
    ) {
        // tutaj jest opcja na wyjście z apki
        const NOW = Date.now();

        if (NOW - last_back_button_click_ts.value < 1250) {
            App.exitApp();
        } else {
            showAppExitInfo();
        }

        last_back_button_click_ts.value = NOW;
    } else {
        extendedRouter.backIfPossible();
    }
}

/*##############
### APP BOOT ###
############# */
async function appBoot() {
    if (generalStore.app_booted) return;

    const SW = new Stopwatch();

    // 1. Cache clear
    await clearUnusedCaches();

    // 2. Nasłuchujemy zmiany statusu sieci
    if (Capacitor.isNativePlatform()) {
        Network.addListener("networkStatusChange", async status => {
            generalStore.setIsOnline(status.connected);
        }).then(h => CAPACITOR_EVENT_LISTENERS_HANDLES.push(h));
    } else {
        window.addEventListener("online", onOnline);
        window.addEventListener("offline", onOffline);
    }

    // 3. Ustalamy initial state dla is_online
    if (Capacitor.isNativePlatform()) {
        const NETWORK_STATUS = await Network.getStatus();
        generalStore.setIsOnline(NETWORK_STATUS.connected);
    } else {
        generalStore.setIsOnline(true);
    }

    // 4. Staramy się przywrócić sesję przy pomocy danych z LS
    let auth_data: undefined | AuthData = undefined;

    if (Capacitor.isNativePlatform()) {
        const AD = await Preferences.get({ key: authStore.AD_PREFERENCES_KEY });
        if (AD.value) {
            try {
                auth_data = JSON.parse(AD.value);
            } catch (err) {
                console.error(err);
            }
        }
    } else {
        auth_data = authStore.auth_data;
    }

    if (auth_data && authStore.isAuthDataValid(auth_data)) {
        try {
            await authStore.logUserIn(auth_data);
        } catch (err) {
            console.error(err);
        }
    } else {
        if (Capacitor.isNativePlatform()) {
            await Preferences.remove({ key: authStore.AD_PREFERENCES_KEY });
        }
    }

    // 5. Oznaczamy w store, że mamy wszystko ogarnięte
    await SW.waitUntil(350);
    generalStore.setAppBooted(true);
}

async function nativeAppBoot() {
    try {
        PushNotifications.addListener(
            "pushNotificationActionPerformed",
            handlePushNotificationActionPerformed
        ).then(h => CAPACITOR_EVENT_LISTENERS_HANDLES.push(h));
        PushNotifications.addListener(
            "pushNotificationReceived",
            handlePushNotificationReceived
        ).then(h => CAPACITOR_EVENT_LISTENERS_HANDLES.push(h));
        SafeArea.addListener("safeAreaChanged", handleSafeAreaChange).then(h =>
            CAPACITOR_EVENT_LISTENERS_HANDLES.push(h)
        );
        App.addListener("backButton", onAndroidBackButtonClick).then(h =>
            CAPACITOR_EVENT_LISTENERS_HANDLES.push(h)
        );
        Keyboard.addListener("keyboardWillShow", onNativeKeyboardWillShow).then(h =>
            CAPACITOR_EVENT_LISTENERS_HANDLES.push(h)
        );
        Keyboard.addListener("keyboardWillHide", onNativeKeyboardWillHide).then(h =>
            CAPACITOR_EVENT_LISTENERS_HANDLES.push(h)
        );

        SafeArea.getSafeAreaInsets().then(v => {
            safe_area_insets_raw.value = v;
        });
        SafeArea.getStatusBarHeight().then(v => {
            status_bar_height.value = v;
        });

        await SplashScreen.hide();

        await waitForMs(500);

        if (Capacitor.getPlatform() === "android") {
            await SafeArea.setImmersiveNavigationBar();
        }

        await setStatusBarStyle(Style.Light);
        await Badge.clear();
        await lockScreenOrientation();
    } catch (err) {
        console.log("MAMY ERROR");
        console.error(err);
    }
}

onMounted(async () => {
    appBoot();
    await generalStore.app_boot_promise;

    if (Capacitor.isNativePlatform()) {
        await nativeAppBoot();
    }
});

onUnmounted(() => {
    CAPACITOR_EVENT_LISTENERS_HANDLES.forEach(h => h.remove());
});
</script>
